<template>
  <v-row>

    <v-col cols="12" md="8">
      <search-box
          :hidden-clear="false"
          label="Cliente"
          popup-label="Selecione um Cliente"
          :id.sync="value.idCliente"
          :nome.sync="value.nome_idCliente"
          :value="cliente"
          :loader-fn="loaderClientes"
          item-key="id"
          item-text="nome">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Cliente encontrado
          </p>
        </template>

      </search-box>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field label="Obs."
                    dense
                    v-model="value.obs"
                    :rules="[
                      v => this.$validators.string.greaterThanOrEquals(v, 1),
                      v => this.$validators.string.lessThanOrEquals(v, 100),
                  ]"/>
    </v-col>
    <v-col cols="12" md="4">
      <search-box
          :hidden-clear="false"
          label="Quadra"
          popup-label="Selecione uma Quadra"
          :id.sync="value.idQuadra"
          :descricao.sync="value.descricao_idQuadra"
          :value="quadra"
          :loader-fn="loaderQuadras"
          item-key="id"
          item-text="descricao">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhuma Quadra encontrada
          </p>
        </template>

      </search-box>
    </v-col>
    <v-col cols="12" md="4">
      <search-box
          :hidden-clear="false"
          label="Local"
          popup-label="Selecione um local"
          :id.sync="value.idLocal"
          :descricao.sync="value.descricao_idLocal"
          :value="local"
          :loader-fn="loaderLocal"
          item-key="id"
          item-text="descricao">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum local encontrado
          </p>
        </template>

      </search-box>
    </v-col>
    <v-col cols="12" md="4">
      <search-box
          :hidden-clear="false"
          label="Horário"
          popup-label="Selecione um Horário"
          :id.sync="value.idHorario"
          :horaInicio.sync="value.horaInicio_idHorario"
          :value="horario"
          :loader-fn="loaderHorarios"
          item-key="id"
          item-text="horaInicio">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Horário encontrado
          </p>
        </template>

      </search-box>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field label="Data Inicio"
                    dense
                    type="date"
                    v-model="value.dataIni"
                    :value="this.inicioMes"
                    :rules="[
                        this.$validators.string.required,
                  ]"/>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field label="Data Fim"
                    dense
                    type="date"
                    v-model="value.dataFim"
                    :value="this.fimMes"
                    :rules="[
                        this.$validators.string.required,
                  ]"/>
    </v-col>
    <v-col cols="12" md="4">
      <lookup-dominio label="Status Presença"
                      v-model="value.flgPresenca"
                      :type="lookups.flgPresenca"
                      :hidden-clear="false"/>
    </v-col>
  </v-row>
</template>

<script>

import {ItemDeDominio} from "../../../../api/itemsDominio";
import {findAll as findAllClientes} from "../../../../api/clientes";
import {findAll as findAllHorarios} from "../../../../api/horarios";
import {findAll as findAllQuadras} from "../../../../api/quadras";
import {findAll as findAllLocal} from "../../../../api/locais";
import LookupDominio from "../../common/LookupDominio";
import OrderInput from "../../common/OrderInput";
import SearchBox from "../../common/SearchBox";
import moment from 'moment';

export default {
  name: "RelatorioFrequenciaForm",
  components: {
    SearchBox,
    LookupDominio,
    OrderInput
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      lookups: {
        flgPresenca: ItemDeDominio.STS_PRESENCA_REL,
      },
      loaderClientes(page, search) {
        return findAllClientes(page, {
          nome: search
        })
      },
      loaderHorarios(page, search) {
        return findAllHorarios(page, {
          descricao: search
        })
      },
      loaderQuadras(page, search) {
        return findAllQuadras(page, {
          descricao: search
        })
      },
      loaderLocal(page, search) {
        return findAllLocal(page, {
          descricao: search
        })
      },
    }
  },
  computed: {
    cliente() {
      if (this.value.idCliente == null) return null;
      return {
        id: this.value.idCliente,
        nome: this.value.nome_idCliente
      }
    },
    horario() {
      if (this.value.idHorario == null) return null;
      return {
        id: this.value.idHorario,
        horaInicio: this.value.horaInicio_idHorario
      }
    },
    quadra() {
      if (this.value.idQuadra == null) return null;
      return {
        id: this.value.idQuadra,
        descricao: this.value.descricao_idQuadra
      }
    },
    local() {
      if (this.value.idLocal == null) return null;
      return {
        id: this.value.idLocal,
        descricao: this.value.descricao_idLocal
      }
    },
    inicioMes: function () {
      if (typeof this.value.dataIni === "undefined"){
        this.value.dataIni = moment().startOf('month').format('YYYY-MM-DD');
      }
    },
    fimMes: function () {
      if (typeof this.value.dataFim === "undefined"){
        this.value.dataFim = moment().endOf('month').format('YYYY-MM-DD');
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


</style>